/* Global Styles */
body {
  font-family: Montserrat, sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('./app-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Add this to fix the background image to the viewport */
  min-height: 100vh; /* Change height to min-height and set it to 100vh to adapt to the viewport height */
  background-color: #F4EBD0;
}

/* body {
  font-family: Montserrat, sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('./app-bg.webp');
  height: 100% ;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center; 
  background-color: #F4EBD0;
  /* background-image: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
  background-size: 100% 300px;
  background-position: 0% 100%;
   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite; 
} */

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

/* Login Styles */

.login-page {
  background-image: url('./login-bg.webp');
  background-size: cover;
  background-position: center;
  background-color: #F4EBD0;
  height: 100%;
}

.app-container {
  background-image: url('./app-bg.webp');
  background-size: cover;
  background-position: center;
  background-color: #F4EBD0;
}

.navbar {
  background-color: #281f32 \;
}

.login-container {
  max-width: 350px;
  margin: 40px auto;
  padding: 20px;
  background-color: #4B6195;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.753);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.login-header {
  text-align: center;
  margin-bottom: 40px;
  color: #fff;
}

.login-header h2 {
  margin-top: 0;
  /* background-color:#000000; */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: leftz;
  
}

.login-form label {
  margin-bottom: 10px;
  color: #FFF;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 30px;
  width: 100%;
  color: #000;
}

.login-form input[type="text"]:focus,
.login-form input[type="password"]:focus {
  border-color: #aaa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form button[type="submit"] {
  background-color: #19A79C;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  display: block;
}

.login-form button[type="submit"]:hover {
  background-color: #fff;
  color: #19A79C;
}

.login-error {
  margin-top: 20px;
  color: firebrick;
  font-size: 14px;
  margin-bottom: 20px;
}

.app-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.top-container {
  margin-top: 30px; /* add some margin top to create distance from Navbar */
  margin-bottom: 30px;
  text-align: center;
}

.container {
  margin-bottom: 20px; /* add margin bottom to container */
}

.columns-container {
  max-width: 800px; /* add max-width to match the width of other filters */
  margin: 0 auto; /* center the container */
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.column {
  background-color: #fff;
  color: #19a79c;
  padding: 20px;
  padding-top: 26px;
 
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.filters-container > * {
  margin: 10px;
}

.actions-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.actions-container > * {
  margin: 10px;
}

button {
  background-color: #4A6399;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #000000;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}



.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-inner {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s;
}

.progress-bar-inner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #fff;
}
